import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Api from '../services/Api'
import ChatComponent from '../components/ChatComponent'
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'react-bootstrap'
import QuestListing from '../components/QuestLisitng'
import Footer from '../components/footer'
import Buttons from '../components/Buttons'
import NestListing from '../components/Nestlising'
import Lottie from 'lottie-react';
import animationData from '../assets/data.json';

const HomePage = () => {

    const isLogged = useSelector((state) => state.user.isLogged);
    const defaultCharactor = useSelector(state => state.characotors.default_charactor);
    const chats = useSelector(state => state.userchats.chats);

    const [readmore, setReadMore] = useState([]);
    const [chatID, setChatID] = useState(0)
    const [character, setCharacter] = useState(null);
    const [data, setData] = useState([])
    const [nestData, setNestData] = useState([])
    const [selectedFilter, setSelectedFilter] = useState('desc')
    const [loading, setLoading] = useState(true)

    const pageRef = useRef(0)
    const hasMoreRef = useRef(false)

    const loadMore = () => {
        Api.get(`chat/quests?limit=2&page=${pageRef.current}&filter=${selectedFilter}`)
            .then((res) => {
                if (res.success) {
                    hasMoreRef.current = res.data.next
                    let _data = [...data, ...res.data.list]
                    setData(_data)
                    pageRef.current++
                } else {
                    hasMoreRef.current = false
                }
            })
            .catch(() => {
                hasMoreRef.current = false
            })
    }

    useEffect(() => {
        setLoading(true)
        Api.get(`chat/quests?limit=2&page=1`)
            .then((res) => {
                if (res.success) {
                    if (res?.data) {
                        if (res.data.next) {
                            pageRef.current = 2
                        }
                        hasMoreRef.current = res.data.next
                        setData(res.data.list)
                    }
                    setNestData(res?.data?.nest || [])
                } else {
                    hasMoreRef.current = false
                }
            }).catch(() => {
                hasMoreRef.current = false
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        if (character == null) {
            if (chats.length > 0) {
                if (chats[chats?.length - 1]?.characters) {
                    setCharacter(chats[chats?.length - 1]?.characters)
                } else {
                    setCharacter(chats[chats?.length - 1]?.character)
                }
                if (chats[chats?.length - 1]) {
                    setChatID(chats[chats?.length - 1].chat_id)
                }
            }
            else {
                setCharacter(defaultCharactor)
            }
        }

    }, [chats])

    const onFilterChange = (e) => {
        setSelectedFilter(e.target.value)
        setLoading(true)
        Api.get(`chat/quests?limit=2&page=1&filter=${e.target.value}`)
            .then((res) => {
                if (res.success) {
                    setData(res.data.list)
                    hasMoreRef.current = res.data.next;
                    if (res.data.next) {
                        pageRef.current = 2;
                    } else {
                        hasMoreRef.current = false;
                    }
                } else {
                    hasMoreRef.current = false;
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleguid = (e, data) => {
        e.stopPropagation();
        window.open(`https://www.nestheads.app/guid?id=${data}`, "_blank")
    }

    return (
        <div className='main_outer_container'>
            <Helmet>
                <title>Home</title>
                <meta name='description' content="Home page" />
            </Helmet>
            <div className='page_container mb-4' >
                <div className='text-center'>
                    <div className='position-relative mx-auto' style={{ width: 'fit-content' }}>
                        <div style={{ fontFamily: "Dimbo", fontSize: '45px' }}>Nestheads Discovery</div>
                        <img src={require('../assets/underline.png')} style={{ width: '170px', position: 'absolute', right: -10, bottom: 0 }} />
                    </div>
                    <div className='fs-6 text-secondary'>Chat discover Nestheads, get a report,  play Nestheads</div>
                </div>
                <div style={{ height: '650px', overflow: 'scroll', position: 'relative', border: '4px solid black', borderRadius: "20px" }} className='my-5 heading_chat_comtainer'>
                    <ChatComponent input='static' chat_id={isLogged ? chatID : 0} character_id={isLogged ? character?.id : "default"} varient="homepage" />
                </div>
                <div style={{ marginBottom: '60px' }}>
                    {/* <div className='position-relative mx-auto' style={{ width: 'fit-content' }}>
                        <div style={{ fontFamily: "Dimbo", fontSize: '45px' }}>Community Quests</div>
                        <img src={require('../assets/underline.png')} style={{ width: '120px', position: 'absolute', right: -10, bottom: 0 }} />
                    </div> */}

                    <div style={{ backgroundColor: "#000", padding: '10px 20px', fontFamily: "Dimbo", fontSize: '18px', borderRadius: '50px', color: '#fff', width: 'fit-content', margin: 'auto' }} >What's in others Heads?</div>

                    <div className='charactor_filter_container' >
                        <div className='my-4 text-right'>
                            <select className='character_filter' title='Filter' onChange={onFilterChange}>
                                <option className='filter_options' selected={selectedFilter == 'desc'} value={'desc'} >Newest</option>
                                <option className='filter_options' selected={selectedFilter == 'acs'} value={'acs'}>Oldest</option>
                                <option className='filter_options' selected={selectedFilter == 'popularity'} value={'popularity'}>Popularity</option>
                                <option className='filter_options' style={{ display: 'none' }} selected={selectedFilter == 'default'} >default</option>
                            </select>
                        </div>
                    </div>
                    {loading &&
                        <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Lottie animationData={animationData}
                                loop={true}
                                autoplay={true}
                                style={{ width: 100, height: 100 }} />
                        </div>
                    }
                    {!loading &&
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={loadMore}
                            hasMore={hasMoreRef.current}
                            loader={<div className="loader d-flex justify-content-center align-items-center my-3 " key={0}><Spinner /></div>}
                        >
                            {data.map((e, i) => {
                                if (e.type == 'M') return <NestListing i={i} e={e} />
                                else return <QuestListing e={e} readmore={readmore} setReadMore={setReadMore} />
                            })}
                        </InfiniteScroll>
                    }
                </div>
            </div >
            <Footer />
        </div>
    )
}

export default HomePage